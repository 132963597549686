import { test } from 'ramda';

import {
  dashboardRedirectUrl,
  settingsUrl,
  helpUrl,
  getStudentTasksUrl,
  PERMALINK_URL,
  PRODUCTION_PERMALINK_URL,
  studyJoinClassUrl,
  newTeacherDashboardUrl,
  textbooksBasePath,
} from 'ms-utils/urls';

export const mathspacePublicEmail = 'hello@mathspace.co';
export const mathspaceSupportEmail = 'support@mathspace.co';
export const copyrightText = `©${new Date().getUTCFullYear()} Mathspace Pty Ltd. All rights reserved.`;

// Routes for the menu
export const menuRoutes = [
  {
    name: 'accounts_dashboard_redirect',
    url: dashboardRedirectUrl,
  },
  {
    name: 'accounts_settings',
    url: settingsUrl,
  },
  {
    name: 'accounts_logout',
    url: '/accounts/logout',
  },
  {
    name: 'admin:index',
    url: '/admin/',
  },
  {
    name: 'help',
    url: helpUrl,
  },
  {
    name: 'student_dashboard',
    url: '/student/',
  },
  {
    name: 'study_join_class',
    url: studyJoinClassUrl,
  },
  {
    name: 'tasks',
    url: getStudentTasksUrl(),
  },
  {
    name: 'textbook',
    url: textbooksBasePath,
  },
  {
    name: 'nte_dashboard',
    url: newTeacherDashboardUrl,
  },
  {
    name: 'teach_reports',
    url: '/teacher/reports/',
  },
  {
    name: 'debug',
    url: '/debug/',
  },
  {
    name: 'graphQL',
    url: '/graphql/public/graphiql/',
  },
  {
    name: 'knowledge_graph',
    url: '/knowledge_graph/',
  },
  {
    name: 'diagnostic_questions_editor',
    url: '/admin/lantern-editor/',
  },
  {
    name: 'storybook_dev',
    url: 'http://localhost:9001/',
  },
  {
    name: 'storybook',
    url: '/storybook/',
  },
];

// API endpoints
export const gqlEndpoint = '/graphql/public/';

// When checking if feature flags are set we only check for the presence of a cookie (not its
// value), so an arbitrary value has been used. It's something truthy in case someone accidentally
// does a check on value instead of presence.
export const defaultCookieValue = '1';
export const nullCookieValue = '0';

// -----------------------------------------------------------------------------
// FONTS
// -----------------------------------------------------------------------------
// Explictly defining a production link for Proxima Nova Semibold for use on
// the PaymentModal component. We're doing this as stripe doesn't
// allow us to define a relative path to our fonts.
export const proximaNovaSemibold = `${PRODUCTION_PERMALINK_URL}/fonts/proxima-nova/proximanova-semibold-webfont.woff2`;

// Symbola
export const symbola = `${PERMALINK_URL}/fonts/symbola/symbola_hint.woff`;

// JS Bridge stuff
// TODO: Clean this up
export const IS_IOS_APP = test(/MathspaceIOS/, window.navigator.userAgent);
