import type { SelectedTab as TaskPageSelectedTab } from 'ms-pages/Tasks/Tasks/SunflowerTasksTabs';
import type {
  SelectedTab as TaskTemplatesListSelectedTab,
  TemplateAuthorship,
} from 'ms-pages/Teacher/TaskTemplates/views/types';
import type { SubstrandSubtopicRecommendationCategory } from 'ms-pages/Textbooks/StudentTextbook/components/SelfDirectedTaskRedirector/__generated__/useCreateSelfDirectedTaskMutation.graphql';
import { serialize } from 'ms-utils/urls/queryParams';

export * from './textbooks';
export * from './teacher';
export const PRODUCTION_PERMALINK_URL =
  'https://mathspace-production-static.mathspace.co/permalink';
export const PERMALINK_URL =
  process.env.NODE_ENV === 'production'
    ? PRODUCTION_PERMALINK_URL
    : '/tmp/static/permalink';
export const AVATAR_PERMALINK_URL =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === null ||
  process.env.NODE_ENV === undefined ||
  process.env.NODE_ENV === 'test'
    ? '/tmp/avatars'
    : `https://mathspace-${process.env.NODE_ENV}-avatars.mathspace.co`;
export const AVATAR_PATH = '/img/avatars/';
export const USE_FACEBOOK_PROFILE_PHOTO_URL =
  '/accounts/login/facebook/?next=/avatar/?complete=facebook';
// App store URLs
export const appStoreUrl = 'itms-apps://itunes.apple.com/app/id1445097311';
export const playStoreUrl =
  'https://play.google.com/store/apps/details?id=co.mathspace';
// Public URLs
export const calendlyUrlAU = 'https://calendly.com/mathspaceau/';
export const calendlyUrlGB = 'https://calendly.com/mathspaceuk/';
export const calendlyUrlUS = 'https://calendly.com/mathspace/demo/';
export const communityUrl = 'https://community.mathspace.co/home';
export const facebookUrl = 'https://facebook.com/learnmaths';
export const forgotPasswordUrl = '/accounts/password/reset/';
export const helpUrl = 'https://help.mathspace.co/';
export const helpUrlAddStudents = `${helpUrl}/en/articles/3660479-using-the-class-code`;
export const homeUrl = '/';
export const teacherUrl = '/teacher';
export const lanternWebsiteUrl = 'https://mathspace.co/waypoints';
export const lanternAppUrl = '/lantern';
export const loginUrl = '/accounts/login';
export const mathspaceAcademyUrl = 'https://mathspace.co/au/academy';
export const paywallUrl = '/subscriptions/';
export const privacyPolicyUrl = '/privacy-policy';
export const schoolsUrl = '/schools/';
export const signupJoinClassUrl = '/signup/join-class/';
export const studentJoinClassUrl = '/join/';
export const tosUrl = '/terms-of-use';
export const twitterUrl = 'https://twitter.com/mathspace';
// Sunflower signup forms
export const signupSunflowerPath = '/accounts/signup/';
export const signupSunflowerUrl = signupSunflowerPath;
export const signupSunflowerSelectorPath =
  '/accounts/signup/selector/:type(teacher|student|parent)?';
export const getSignupSunflowerSelectorUrl = (
  selected?: 'teacher' | 'student' | 'parent',
) => `/accounts/signup/selector/${selected != null ? selected : ''}`;
export const signupSunflowerTeacherPath = '/accounts/signup/teacher/';
export const signupSunflowerTeacherUrl = signupSunflowerTeacherPath;
export const signupSunflowerStudentPath = '/accounts/signup/student/';
export const signupSunflowerStudentUrl = signupSunflowerStudentPath;
export const signupSunflowerParentPath = '/accounts/signup/parent/';
export const signupSunflowerParentUrl = signupSunflowerParentPath;
export const signupSunflowerStudentValidDobPath =
  '/accounts/signup/student/valid-dob';
export const signupSunflowerStudentValidDobUrl =
  signupSunflowerStudentValidDobPath;
export const signupSunflowerStudentContactTeacherPath =
  '/accounts/signup/student/contact-teacher';
export const signupSunflowerStudentContactTeacherUrl =
  '/accounts/signup/student/contact-teacher';
export const signupSunflowerSchoolSelectionPath =
  '/accounts/signup/:origin/select-school';
export const signupSunflowerClassCreationPath =
  '/accounts/signup/teacher/create-class';
export type SunflowerSchoolSelectorParams = {
  origin: 'teacher' | 'student' | 'parent';
};
export const getSunflowerSchoolSelectionUrl = ({
  origin,
}: SunflowerSchoolSelectorParams) => `/accounts/signup/${origin}/select-school`;
export const signupSunflowerJoinClassUrl = '/accounts/signup/join-class/';
// User URLs
export const accountsAvatarUrl = '/avatar/';
export const dashboardRedirectUrl = '/accounts/go/dashboard/';
export const studentDashboardPath = '/student/';
export const getStudentDashboardUrl = () => '/student/';
export const onboardingUrl = '/accounts/onboarding/';
export const deepLinkReturnUrl = '/deep-link/return-url';
export const schoolsPLPReturnUrl = '/schoolsplp/return-redirect';
export const studyJoinClassUrl = '/join-class/';
export const studyJoinClassPath = '/join-class/';
export const settingsUrl = '/accounts/settings/';
export const changePasswordUrl = '/accounts/change-password/';
export const changeTemporaryPasswordUrl = '/accounts/change-temp-password/';
export const logoutUrl = '/accounts/logout';
export const studentOnboardingPath = '/student/onboarding';
export const studentOnboardingUrl = '/student/onboarding';
export const studentOnboardingGradePath = '/student/onboarding/grade';
export const studentOnboardingGradeUrl = '/student/onboarding/grade';
export const studentOnboardingAvatarPath = '/student/onboarding/avatar';
export const studentOnboardingAvatarUrl = '/student/onboarding/avatar';
export const studentOnboardingCompleteUrl = '/student/onboarding/complete';
export const studentActivityPath = '/student/activity/';
export const getStudentActivityUrl = () => '/student/activity/';
export const aiTutorSubscriptionPath = '/subscriptions/ai-tutor';
export const aiTutorSubscriptionConfirmationPath =
  '/subscriptions/ai-tutor/confirmation';
type StudentTasksUrlParams = {
  selectedTab?: TaskPageSelectedTab | undefined;
  showOverdueTasks?: 'true' | undefined;
  includeNonAccessibleTasks?: 'true' | undefined;
};
export const studentTasksPath = '/student/tasks/';
export const getStudentTasksUrl = ({
  selectedTab,
  showOverdueTasks,
  includeNonAccessibleTasks,
}: StudentTasksUrlParams = {}) =>
  `${studentTasksPath}${serialize({
    selectedTab,
    showOverdueTasks,
    includeNonAccessibleTasks,
  })}`;
type SelfDirectedAdaptiveTaskUrlParamsBase = {
  subtopicId: string;
};
type SelfDirectedAdaptiveTaskUrlParamsNoSubtopicRecommendation = {
  isSubtopicRecommendation?: false | undefined;
  recommendationCategory?: null | undefined;
};
type SelfDirectedAdaptiveTaskUrlParamsWithSubtopicRecommendation = {
  isSubtopicRecommendation: true;
  recommendationCategory: SubstrandSubtopicRecommendationCategory;
};
export type SelfDirectedAdaptiveTaskUrlParams =
  SelfDirectedAdaptiveTaskUrlParamsBase &
    (
      | SelfDirectedAdaptiveTaskUrlParamsNoSubtopicRecommendation
      | SelfDirectedAdaptiveTaskUrlParamsWithSubtopicRecommendation
    );
export const selfDirectedAdaptiveTaskPath = `${studentDashboardPath}subtopic/:subtopicId/self-directed-task`;
export const getSelfDirectedAdaptiveTaskUrl = ({
  subtopicId,
  isSubtopicRecommendation,
  recommendationCategory,
}: SelfDirectedAdaptiveTaskUrlParams) =>
  `${studentDashboardPath}subtopic/${subtopicId}/self-directed-task${
    Boolean(isSubtopicRecommendation) && recommendationCategory != null
      ? `?isSubtopicRecommendation=true&recommendationCategory=${recommendationCategory}`
      : ''
  }`;
// dynamic urls builders
export const previewWorkoutLaunchUrl = (problemTemplateID: number) =>
  `/work-launch/PreviewWorkout/ebook/${problemTemplateID}/launch/`;
export const previewWorkoutWithSubtopicLaunchUrl = (
  subtopicID: number,
  problemTemplateID: number,
) =>
  `/work-launch/PreviewWorkout/subtopic/${subtopicID}/pt/${problemTemplateID}/launch/`;
export const getWorkoutUrl = (
  workoutId: string,
  problemId?: string | undefined,
  isSubtopicRecommendation?: boolean,
) =>
  `/work/${
    isSubtopicRecommendation ? 'subtopic-recommendation/' : ''
  }${workoutId}/${problemId ?? ''}`;
export const getScorecardUrl = (
  workoutId: string,
  problemId?: string | undefined,
) => `/student/scorecard/${workoutId}/${problemId ?? ''}`;
export const getAdminScorecardUrl = (
  workoutId: string,
  problemId?: string | undefined,
) => `/admin/scorecard/${workoutId}/${problemId ?? ''}`;
type TeacherClassStudentsParams = {
  schoolId?: string | undefined;
  classId?: string | undefined;
};
export const teacherClassStudentsUrl = ({
  schoolId,
  classId,
}: TeacherClassStudentsParams = {}) =>
  `/teacher/schools/${schoolId || ':schoolId'}/classes/${
    classId || ':classId'
  }/`;
export const taskTemplateLegacyPathToRedirect =
  '/teacher/schools/:schoolId/templates/:worksheetTemplateId/';
export const taskTemplateGroupsLegacyPathToRedirect =
  '/teacher/schools/:schoolId/template-groups/:worksheetTemplateGroupId/';
export const taskTemplateGroupsTemplateLegacyPathToRedirect =
  '/teacher/schools/:schoolId/template-groups/:worksheetTemplateGroupId/templates/:worksheetTemplateId';
export const taskTemplatesListLegacyPathToRedirect =
  '/teacher/schools/:schoolId/templates/';
export const globalSearchPath = '/teacher/search/';

export const teacherWorksheetTemplatesListUrlPath = '/teacher/templates/';
export const teacherWorksheetTemplateGroupsListUrlPath =
  '/teacher/template-groups/';
type TeacherWorksheetTemplatesListUrlParams = {
  selectedTab?: TaskTemplatesListSelectedTab;
  templateAuthorshipFilter?: TemplateAuthorship;
};
export const getTeacherWorksheetTemplatesListUrl = ({
  selectedTab,
  templateAuthorshipFilter,
}: TeacherWorksheetTemplatesListUrlParams) =>
  `/teacher/templates/${serialize({ selectedTab, templateAuthorshipFilter })}`;
type TeacherWorksheetTemplatesDetailUrlParams = {
  worksheetTemplateId: string | undefined;
};
export const teacherWorksheetTemplatesDetailUrlPath =
  '/teacher/templates/:worksheetTemplateId';
export const getTeacherWorksheetTemplatesDetailUrl = ({
  worksheetTemplateId,
}: TeacherWorksheetTemplatesDetailUrlParams) =>
  `/teacher/templates/${worksheetTemplateId}/`;
type TeacherWorksheetTemplateGroupsDetailUrlParams = {
  worksheetTemplateGroupId: string;
  ancestorGroupIds?: string[] | undefined;
};

export const taskTemplatePrintPath = '/teacher/templates/:taskTemplateId/print';
type TaskTemplatePrintUrlParams = { taskTemplateId: string };
export const getTaskTemplatePrintUrl = ({
  taskTemplateId,
}: TaskTemplatePrintUrlParams) =>
  taskTemplatePrintPath.replace(':taskTemplateId', taskTemplateId);

export const taskTemplateWorkedSolutionsPrintPath =
  '/teacher/templates/:taskTemplateId/worked-solutions/print';
export const getTaskTemplateWorkedSolutionsPrintUrl = ({
  taskTemplateId,
}: TaskTemplatePrintUrlParams) =>
  taskTemplateWorkedSolutionsPrintPath.replace(
    ':taskTemplateId',
    taskTemplateId,
  );

export const subtopicQuestionsWorkedSolutionsPrintPath =
  '/textbooks/subtopic/:subtopicId/worked-solutions/print';
export type SubtopicQuestionsWorkedSolutionsPrintUrlParams = {
  subtopicId: string;
};
export const getSubtopicQuestionsWorkedSolutionsPrintUrl = ({
  subtopicId,
}: SubtopicQuestionsWorkedSolutionsPrintUrlParams) =>
  subtopicQuestionsWorkedSolutionsPrintPath.replace(':subtopicId', subtopicId);

export const taskReportQuestionsWorkedSolutionsPrintPath =
  '/teacher/task-report/:taskId/worked-solutions/print';
export type TaskReportQuestionsWorkedSolutionsPrintUrlParams = {
  taskId: string;
};
export const getTaskReportQuestionsWorkedSolutionsPrintUrl = ({
  taskId,
}: TaskReportQuestionsWorkedSolutionsPrintUrlParams) =>
  taskReportQuestionsWorkedSolutionsPrintPath.replace(':taskId', taskId);

export const teacherWorksheetTemplateGroupsDetailUrlPath =
  '/teacher/template-groups/:worksheetTemplateGroupId/';

// Currently we are only using the last templateGroupId in the URL
// to retrieve the template group details.
// We are not using the parent templateGroupIds in the URL,
// they are presentational.
// Wildcard * is used to match the parent template groups in the url here.
// If we are interested in matching the parent template groups
// (as a whole string of one or more template group ids),
// since we are using an old version of react-router-dom (5.2.0),
// instead of using the wildcard, we can still use the following path:
// '/teacher/template-groups/:parentWorksheetTemplateGroupIds*/:worksheetTemplateGroupId/';
// But this type of path is not supported in the latest version of react-router-dom v6,
// they removed path-to-regexp library support which was used to parse the path under the hood.
// So, to make this URL future-proof, we have to use a wildcard here.
// See these links for more details:
// https://reactrouter.com/en/6.14.0/start/faq#what-happened-to-regexp-routes-paths
// https://reactrouter.com/en/main/route/route#splats
export const teacherWorksheetTemplateGroupsDetailUrlPathWithSubfolders =
  '/teacher/template-groups/*/:worksheetTemplateGroupId/';

const getPathFromAncestorGroupIds = (
  ancestorGroupIds: string[] | undefined,
): string =>
  ancestorGroupIds != null && ancestorGroupIds.length > 0
    ? `/${ancestorGroupIds.join('/')}`
    : '';

export const getTeacherWorksheetTemplateGroupsDetailUrl = ({
  worksheetTemplateGroupId,
  ancestorGroupIds,
}: TeacherWorksheetTemplateGroupsDetailUrlParams) =>
  `/teacher/template-groups${getPathFromAncestorGroupIds(
    ancestorGroupIds,
  )}/${worksheetTemplateGroupId}/`;

type TeacherWorksheetTemplatesDetailGroupUrlParams = {
  worksheetTemplateGroupId: string;
  worksheetTemplateId: string;
  ancestorGroupIds?: string[] | undefined;
};
export const teacherWorksheetTemplatesDetailGroupUrlPath =
  '/teacher/template-groups/:worksheetTemplateGroupId/templates/:worksheetTemplateId/';

// We are not using the parent templateGroupIds in the URL,
// they are presentational, despite having worksheetTemplateGroupId
// as a parameter in the path.
// Wildcard * is used to match the parent template groups in the url here.
// If we are interested in matching the parent template groups
// (as a whole string of one or more template group ids),
// since we are using an old version of react-router-dom (5.2.0),
// instead of using the wildcard, we can still use the following path:
// '/teacher/template-groups/:parentWorksheetTemplateGroupIds*/:worksheetTemplateGroupId/templates/:worksheetTemplateId/';
// But this type of path is not supported in the latest version of react-router-dom v6,
// they removed path-to-regexp library support which was used to parse the path under the hood.
// So, to make this URL future-proof, we have to use a wildcard here.
// See these links for more details:
// https://reactrouter.com/en/6.14.0/start/faq#what-happened-to-regexp-routes-paths
// https://reactrouter.com/en/main/route/route#splats
export const teacherWorksheetTemplatesDetailGroupUrlPathWithSubfolders =
  '/teacher/template-groups/*/:worksheetTemplateGroupId/templates/:worksheetTemplateId/';
export const getTeacherWorksheetTemplatesDetailGroupUrl = ({
  worksheetTemplateId,
  worksheetTemplateGroupId,
  ancestorGroupIds,
}: TeacherWorksheetTemplatesDetailGroupUrlParams) => {
  return `/teacher/template-groups${getPathFromAncestorGroupIds(
    ancestorGroupIds,
  )}/${worksheetTemplateGroupId}/templates/${worksheetTemplateId}/`;
};
export type ProblemPreviewUrlParams = {
  previewWorkoutCreationToken: string;
};
export const problemPreviewUrlPath =
  '/problem-preview/:previewWorkoutCreationToken';
export const getProblemPreviewUrl = ({
  previewWorkoutCreationToken,
}: ProblemPreviewUrlParams) =>
  `/problem-preview/${previewWorkoutCreationToken}`;
export type SharesUrlReaderParams = {
  shareId: string;
};
export const sharesReaderUrlPath = '/student/shares/:shareId/';
export const getSharesReaderUrl = ({ shareId }: SharesUrlReaderParams) =>
  `/student/shares/${shareId}`;
export const sharesUrlPath = '/student/shares';
export const getSharesUrl = '/student/shares';
export const getUserNotificationActionUrl = (notificationPk: number) =>
  `/notifications/${notificationPk}/action/`;
// Engage Tasks
export type EngageWorkoutUrlParams = {
  engageWorkoutId: string;
};
export const engageWorkoutPath = '/engage-workout/:engageWorkoutId';
export const getEngageWorkoutUrl = ({
  engageWorkoutId,
}: EngageWorkoutUrlParams) => `/engage-workout/${engageWorkoutId}`;
export const uploadEngageActivityPhotoUrl =
  '/engage-workout/api/upload-engage-activity-photo';
// Skill checkin "accountless" demo landing page
export type SkillCheckinDemoUrlParams = {
  pageSlug: string;
};
export const skillCheckinDemoPath = '/demo/skillscheckindemo/';
export const getSkillCheckinDemoUrl = ({
  pageSlug,
}: SkillCheckinDemoUrlParams) =>
  `/demo/skillscheckindemo/?locationCode=${pageSlug}`;
// Preview student experience
export const previewStudentViewRedirectUrl = '/accounts/go/student';
export const exitStudentExperienceRedirectUrl = '/accounts/go/teacher';
export const getPreviewStudentViewRedirectUrl = ({
  classId,
}: {
  classId?: string | undefined;
}) => {
  return `${previewStudentViewRedirectUrl}${
    classId != null ? `?transferToClass=${classId}` : ''
  }`;
};
// Milo "accountless" demo landing page
export const miloDemoPath = '/demo/milodemo/';
// Problem preview that is exposed to Demo users.
// See this for more: https://github.com/mathspace/mathspace/pull/11375
export const miloDemoProblemPath =
  '/problem-preview/26470:en-AU:Subtopic-281708';
