/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type featureFlagsV2 = {
    readonly canPreviewProblemEventDataInSkills: boolean;
    readonly enableAiOpenPrompt: boolean;
    readonly enableAiPremiumTrial: boolean;
    readonly gamificationEnableChallengesDaily: boolean;
    readonly gamificationEnableCustomisation: boolean;
    readonly gamificationEnableLeaderboardClassSelectionPrompt: boolean;
    readonly gamificationEnableLeaderboardDock: boolean;
    readonly gamificationEnableLeaderboardModalV2: boolean;
    readonly gamificationEnableLeaderboardNotification: boolean;
    readonly gamificationEnableLeaderboardTab: boolean;
    readonly gamificationEnableLiveModePromote: boolean;
    readonly gamificationEnableMonthlyExpedition: boolean;
    readonly gamificationEnablePointsPromote: boolean;
    readonly gamificationEnablePointsQuestionStreak: boolean;
    readonly gamificationEnablePointsUpdate: boolean;
    readonly gamificationEnableTeacherStickers: boolean;
    readonly gamificationEnableTugOfWar: boolean;
    readonly globalSearchEnableMVP: boolean;
    readonly reportsEnableRecommendTasks: boolean;
    readonly templatesEnableSortLastAssigned: boolean;
    readonly workbookEnableIncorrectStepPrompt: boolean;
    readonly workbookEnableNudgeAutoclick: boolean;
    readonly workbookEnableSpanishSupport: boolean;
    readonly workbookEnableSuggestedActions: boolean;
    readonly workbookHideSkipStepExplanation: boolean;
};
export type featureFlagsV2$data = featureFlagsV2;
export type featureFlagsV2$key = {
    readonly " $data"?: featureFlagsV2$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"featureFlagsV2">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "featureFlagsV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canPreviewProblemEventDataInSkills",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiPremiumTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableChallengesDaily",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableCustomisation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardClassSelectionPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardDock",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardModalV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLiveModePromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableMonthlyExpedition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsPromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsQuestionStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTeacherStickers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTugOfWar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalSearchEnableMVP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportsEnableRecommendTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableSortLastAssigned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableIncorrectStepPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableNudgeAutoclick",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableSpanishSupport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableSuggestedActions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookHideSkipStepExplanation",
      "storageKey": null
    }
  ],
  "type": "FeatureFlagsV2",
  "abstractKey": null
};
(node as any).hash = '917158f300328d0905a9af8ac820ac46';
export default node;
