// DO NOT MODIFY THIS FILE
// Generated by bin/generate_feature_flags_v2.py
import { graphql } from 'react-relay';

graphql`
  fragment featureFlagsV2 on FeatureFlagsV2 @relay(mask: false) {
    canPreviewProblemEventDataInSkills
    enableAiOpenPrompt
    enableAiPremiumTrial
    gamificationEnableChallengesDaily
    gamificationEnableCustomisation
    gamificationEnableLeaderboardClassSelectionPrompt
    gamificationEnableLeaderboardDock
    gamificationEnableLeaderboardModalV2
    gamificationEnableLeaderboardNotification
    gamificationEnableLeaderboardTab
    gamificationEnableLiveModePromote
    gamificationEnableMonthlyExpedition
    gamificationEnablePointsPromote
    gamificationEnablePointsQuestionStreak
    gamificationEnablePointsUpdate
    gamificationEnableTeacherStickers
    gamificationEnableTugOfWar
    globalSearchEnableMVP
    reportsEnableRecommendTasks
    templatesEnableSortLastAssigned
    workbookEnableIncorrectStepPrompt
    workbookEnableNudgeAutoclick
    workbookEnableSpanishSupport
    workbookEnableSuggestedActions
    workbookHideSkipStepExplanation
  }
`;
export const FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS = [
  'canPreviewProblemEventDataInSkills',
  'enableAiOpenPrompt',
  'enableAiPremiumTrial',
  'gamificationEnableChallengesDaily',
  'gamificationEnableCustomisation',
  'gamificationEnableLeaderboardClassSelectionPrompt',
  'gamificationEnableLeaderboardDock',
  'gamificationEnableLeaderboardModalV2',
  'gamificationEnableLeaderboardNotification',
  'gamificationEnableLeaderboardTab',
  'gamificationEnableLiveModePromote',
  'gamificationEnableMonthlyExpedition',
  'gamificationEnablePointsPromote',
  'gamificationEnablePointsQuestionStreak',
  'gamificationEnablePointsUpdate',
  'gamificationEnableTeacherStickers',
  'gamificationEnableTugOfWar',
  'globalSearchEnableMVP',
  'reportsEnableRecommendTasks',
  'templatesEnableSortLastAssigned',
  'workbookEnableIncorrectStepPrompt',
  'workbookEnableNudgeAutoclick',
  'workbookEnableSpanishSupport',
  'workbookEnableSuggestedActions',
  'workbookHideSkipStepExplanation',
] as const;
