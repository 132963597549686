/** *************************************************************************************
 *
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * Use python manage.py generate_featureflags to regenerate.
 *
 ***************************************************************************************/
import { graphql } from 'react-relay';

graphql`
  fragment featureFlags on TypedFeatureFlag @relay(mask: false) {
    canAccessNewDistrictReport
    canAccessNewDistrictReportImpact
    canAccessNewDistrictReportSchoolLevel
    canAccessNewDistrictReportTemplates
    canAssignDiscoveryCheckIn
    canChooseSkillCheckin
    canvasEnableGradePassback
    checkinsEnableGrowthPeriodFilter
    classReportsEnableCheckInActivityDetail
    enableAiOpenPrompt
    enableAiPremiumTrial
    enableAiSuggestQuestions
    enableCATFAv2AssignCheckin
    enableFeatureFlagsV2
    enableMTSInCATFA
    enableOptInDiscoveryCheckIn
    gamificationEnableChallengesDaily
    gamificationEnableCustomisation
    gamificationEnableLeaderboardDock
    gamificationEnableLeaderboardNotification
    gamificationEnableLeaderboardTab
    gamificationEnableLiveModePromote
    gamificationEnablePointsPromote
    gamificationEnablePointsQuestionStreak
    gamificationEnablePointsUpdate
    gamificationEnableTugOfWar
    hasDismissedOnboardingModalInAdaptiveIntro
    hasEnabledAccessibilityMode
    hasStudentAppSpa
    hasUpdatedCorrectStepIcons
    hasUpdatedQuestionsView
    insightsEnableLearningHighlights
    perfEnableModelDataLoaders
    perfEnableOptimisedPaginateQueryset
    perfEnableTextbookOptimisation
    printEnablePageBreaksAfterPTs
    problemContentsShortcutKillswitchEnabled
    showLoginV2
    showMaintenanceBanner
    showSkillsBookByDefault
    showSkillsBookGridView
    showWorkbookOnboarding
    skillsReportEnableSearchStandard
    skillsReportEnableSort
    supportEnableHubspotBubble
    templatesEnableDistrictSharingPermissions
    templatesEnableDualWriteTreeStructure
    templatesEnableSubfolders
    textbookDisableSecondaryAssignCTA
    useSanaConfidenceInterval
    useSanaCorrelationsUpdator
    useSanaTimeDependentUncertaintyInjection
    workbookEnableIncorrectStepPrompt
    workbookEnableSpanishSupport
    adminReports
    advancedReports
    allowMultipleTeachersInClass
    assignTasksToIndividuals
    assignableTasks
    bulkAssignTasks
    classicUi
    demoCheckin
    diagnostics
    engageLite
    engageTasks
    primaryTeacherNotes
    printableWorksheets
    schoolReports
    teacherReporting
    templates
    textbook
    textbookOverviews
    topicAssessment
    unlimitedClasses
    unlimitedOpenPrompt
    worksheetAnswerKeys
    worksheets
    canEditLanternQuestions
  }
`;

export const FEATURE_FLAG_NAMES_FOR_TEST_MOCKS = [
  'canAccessNewDistrictReport',
  'canAccessNewDistrictReportImpact',
  'canAccessNewDistrictReportSchoolLevel',
  'canAccessNewDistrictReportTemplates',
  'canAssignDiscoveryCheckIn',
  'canChooseSkillCheckin',
  'canvasEnableGradePassback',
  'checkinsEnableGrowthPeriodFilter',
  'classReportsEnableCheckInActivityDetail',
  'enableAiOpenPrompt',
  'enableAiPremiumTrial',
  'enableAiSuggestQuestions',
  'enableCATFAv2AssignCheckin',
  'enableFeatureFlagsV2',
  'enableMTSInCATFA',
  'enableOptInDiscoveryCheckIn',
  'gamificationEnableChallengesDaily',
  'gamificationEnableCustomisation',
  'gamificationEnableLeaderboardDock',
  'gamificationEnableLeaderboardNotification',
  'gamificationEnableLeaderboardTab',
  'gamificationEnableLiveModePromote',
  'gamificationEnablePointsPromote',
  'gamificationEnablePointsQuestionStreak',
  'gamificationEnablePointsUpdate',
  'gamificationEnableTugOfWar',
  'hasDismissedOnboardingModalInAdaptiveIntro',
  'hasEnabledAccessibilityMode',
  'hasStudentAppSpa',
  'hasUpdatedCorrectStepIcons',
  'hasUpdatedQuestionsView',
  'insightsEnableLearningHighlights',
  'perfEnableModelDataLoaders',
  'perfEnableOptimisedPaginateQueryset',
  'perfEnableTextbookOptimisation',
  'printEnablePageBreaksAfterPTs',
  'problemContentsShortcutKillswitchEnabled',
  'showLoginV2',
  'showMaintenanceBanner',
  'showSkillsBookByDefault',
  'showSkillsBookGridView',
  'showWorkbookOnboarding',
  'skillsReportEnableSearchStandard',
  'skillsReportEnableSort',
  'supportEnableHubspotBubble',
  'templatesEnableDistrictSharingPermissions',
  'templatesEnableDualWriteTreeStructure',
  'templatesEnableSubfolders',
  'textbookDisableSecondaryAssignCTA',
  'useSanaConfidenceInterval',
  'useSanaCorrelationsUpdator',
  'useSanaTimeDependentUncertaintyInjection',
  'workbookEnableIncorrectStepPrompt',
  'workbookEnableSpanishSupport',
  'classicUi',
  'diagnostics',
  'teacherReporting',
  'textbook',
  'worksheets',
  'unlimitedClasses',
  'adminReports',
  'advancedReports',
  'allowMultipleTeachersInClass',
  'assignableTasks',
  'assignTasksToIndividuals',
  'engageLite',
  'schoolReports',
  'textbookOverviews',
  'worksheetAnswerKeys',
  'bulkAssignTasks',
  'engageTasks',
  'printableWorksheets',
  'templates',
  'primaryTeacherNotes',
  'topicAssessment',
  'demoCheckin',
  'unlimitedOpenPrompt',
  'canEditLanternQuestions',
] as const;
